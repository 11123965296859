<template>
<label class="search-field h-7 2xl:h-9 rounded-lg 2xl:rounded-xl text-sm px-3 2xl:px-5">
	<input
		:placeholder="computedPlaceholder"
		class="search-field__input"
		type="text"
		v-model="modelValue"
	>
	<search-icon class="search-field__icon w-4 h-4 2xl:w-6 2xl:h-6 text-primary fill-current"/>
</label>
</template>

<script>
import searchIcon from '@/assets/icons/dashboard/search.svg';

export default {
	name: "search-field",
	components: {
		searchIcon,
	},
	props: {
		placeholder: {
			type: String,
			default: "Search",
		},
		value: String,
	},
	computed: {
		modelValue: {
			get() {
				return this.value;
			},
			set(newValue) {
				this.$emit("input", newValue);
			},
		},
		computedPlaceholder() {
			return this.placeholder || this.$t('fields.search')
		}
	},
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/functions.scss';

.search-field {
	display: inline-flex;
	align-items: center;
	background-color: #DFDFDF;
	&__input {
		flex-grow: 1;
		background: none;
	}
	&__input {
		font-weight: bold;
		color: #000000;
		&::placeholder {
			@apply text-gray-500;
		}
	}
	&__icon {
		cursor: pointer;
		margin-left: auto;
	}
}
</style>