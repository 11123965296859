import { DateTime } from 'luxon'

const customFormats = {
    ISOwithoutT: "yyyy-MM-dd HH:mm:ss"
}

export const DATETIME_FORMAT_DEF = 'default_datetime'
export const DATETIME_FORMAT_1 = 'date_at_time'

const componentDateFormatList = {
    [DATETIME_FORMAT_DEF]: "yyyy-MM-dd hh:mm:ss a",
    [DATETIME_FORMAT_1]: "yyyy-MM-dd 'at' hh:mm:ss a"
}

export function getLocalDateFormat(dateTimeString, { format = DATETIME_FORMAT_DEF } = {}) {
    const localizedDate = getLocalizedDate(dateTimeString);
    return formatDate(localizedDate, componentDateFormatList[format]);
}

function getLocalizedDate(dateTimeString) {
    const isoFormat = DateTime.fromISO(dateTimeString, { zone: 'UTC' });
    const ISOwithoutT = DateTime.fromFormat(dateTimeString, customFormats.ISOwithoutT, { zone: 'UTC' });
    if (isoFormat.isValid) {
        return setDateLocale(isoFormat);
    } else if (ISOwithoutT.isValid) {
        return setDateLocale(ISOwithoutT);
    } else {
        throw new Error("Invalid date format");
    }
}

function setDateLocale(luxonDateTime) {
    return luxonDateTime.setZone(DateTime.local().zoneName);
}

function formatDate(luxonDateTime, format) {
    return luxonDateTime.toFormat(format);
}
